<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入关键词" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
        infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
         <div class="list_row">
                <div class="flex">
                    <div><label for="">工作点名称：</label>{{item.oname}}</div>
                    <!-- <div><label for="">编号：</label>{{item.id}}</div> -->
                </div>
                <div class="flex">
                    <div><label for="">工作点地址：</label>{{item.address}}</div>
                   
                </div>
                 <div class="flex">
                    <div><label for="">所属部门：</label>{{item.dname}}</div>
                 </div>
                  <div class="flex">
                    <div><label for="">负责人：</label>{{item.sname}}</div>
                    <div><label for="">组长：</label>{{item.wname}}</div>              
                 </div>
                  
                <div class="flex">
                    <div><label for="">服务人员数：</label>{{item.workernum}}</div>
                    <div><label for="">基数：</label>{{item.base}}</div>
                </div>
            </div>
            <div class="btn_con">
                <!-- <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button> -->
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
                <!-- <a-popconfirm title="确定要关闭吗?"  @confirm="() => off(item.id)">
                    <a-button type="danger" size="small" ghost>关闭</a-button>
                </a-popconfirm> -->
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                    <a-button type="danger" size="small" ghost>删除</a-button>
                </a-popconfirm>
            </div>
        </div>
           <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
    </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import {requestXml,ajaxUrl} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},  
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            ajaxUrl:ajaxUrl,
            showModal:false, //添加工时弹框
            onItem:'',
            tempList:[], //工人列表
            onTemp:[],
            skillList:[], //技能列表
            onSkill:undefined,
            laborTime:'', //出勤工时
            laborDate:'', //出勤日期
             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'key':this.key,'department':this.onTeam,'responsibility':this.onStaff,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/worker/operatAdd") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.key
            this.onTeam=data.department
            this.onStaff=data.responsibility
        }
        this.getData();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
        // 搜索
        onSearch(){
            this.getData();
        },
       getData() {
            this.dataLoadStop = true;
            requestXml("/jieb/Operating/myoperat","POST",(res) => {
               this.data = res.list;
                // this.pagination.total = Number(res.page.total);
                // this.pagination.pageSize = Number(res.page.pagesize);
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadNomore = true
                // }
                 this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{key:this.key,department:this.onTeam,responsibility:this.onStaff,page: {
                        curpage: this.pagination.current,
                        pagesize: this.pagination.pageSize,
                    }})
        },
      // 编辑
        edit(id){
           this.$router.push({
                path: "/scrm_wap/worker/operatAdd",
                query: {
                    id: id,
                }
            });
        },
        // 删除
        onDelete(id){
            requestXml("/jieb/Operating/operatdel","POST",(res) => {
                console.log(res)
                this.pagination.current=1;
                this.data=[];
                this.getData();
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
    .lists .item{
        display: flex;
    }    
    .lists .right{
        text-align: left;
        padding-left: 20px;
        width: calc(100% - 100px );
    }
     .lists .right .flex{
         display: flex;
         justify-content: space-between;
     }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
    .add_title{
        padding-top: 10px;padding-bottom: 5px;
    }
    
</style>
